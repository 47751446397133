import React from "react";

import Spacer from "../../components/Spacer/Spacer";
import { useFetchElements } from "../../utils/hooks";
import { pageIds } from "../../api/content";
import { renderFormattedText } from "../../utils/helpers";

const SectionOne = () => {

    const pageElements = useFetchElements(pageIds.generalpublicpage);



    return (
        <div className="lPurple">


            <div className="limit">

                <h1 style={{ maxWidth: '600px', margin: 'auto' }}>
                    What are mycosis fungoides and Sézary syndrome<sup>1–3</sup>?
                </h1>
                <div className="limit06">
                    <ul className="pBullet">
                        {pageElements &&
                            renderFormattedText(pageElements.purpleSection.value, "pBullet")}
                     
                    </ul>
                </div>


            </div>
            <Spacer className={'spacer--arrow-eighteen'} />
        </div>
    )
}

export default SectionOne;
