import React from "react";
import {Link} from "react-router-dom";

const ApproverNav = ({footerNavPatient, adverseOpen}) => {

    return (
        <div className="approved">
            {footerNavPatient.map((item, i) =>
                <span key={i}>
                    <div className="nbspF"> &nbsp;|&nbsp; </div>
                    {item.url === '#open-modal' ? (
                        <button onClick={adverseOpen} className="footer__link" key={item.i} data-title={item.title}>{item.title}</button>
                    ) : item.url === '/prescribing-information-patient' ? (
                        <button className="footer__link" key={item.i} data-title={item.title} onClick={ () => {if(window.confirm('You are about to leave the site. Are you sure you want to continue?')){window.location.href='https://www.medicines.org.uk/emc/product/11174/pil'};}}>{item.title}</button>
                    ) : item.url === '/SPC' ? (
                        <button className="footer__link" key={item.i} data-title={item.title} onClick={ () => {if(window.confirm('You are about to leave the site. Are you sure you want to continue?')){window.location.href='https://www.medicines.org.uk/emc/product/11174'};}}>{item.title}</button>
                    ) : item.url === '/EPAR' ? (
                        <button className="footer__link" key={item.i} data-title={item.title} onClick={ () => {if(window.confirm('You are about to leave the site. Are you sure you want to continue?')){window.location.href='https://www.ema.europa.eu/en/documents/assessment-report/poteligeo-epar-public-assessment-report_en.pdf '};}}>{item.title}</button>
                    ) :  (
                        <button className="footer__link" key={item.i} data-title={item.title} onClick={ () => {if(window.confirm('You are about to leave the site. Are you sure you want to continue?')){window.location.href='/prescribing-information-patient'};}}>{item.title}</button>
                    )}
                </span>
            )}
        </div>
    )
}

export default ApproverNav;