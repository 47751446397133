import React from "react";

import Spacer from "../../components/Spacer/Spacer";
import { useFetchElements } from "../../utils/hooks";
import { pageIds } from "../../api/content";
import { renderFormattedText } from "../../utils/helpers";

const SectionTwo = () => {
    const pageElements = useFetchElements(pageIds.generalpublicpage);
    return (
        <div className="lBlue">
            <div className="limit">
                <div className="graph text-only patient-text">
                    <h4>MYCOSIS FUNGOIDES<sup>1,3</sup></h4>
                    {pageElements &&
                    renderFormattedText(pageElements.lightBlueSectionOne.value, "pBottom")}
                </div>
                <div className="graph text-only patient-text">
                    <h4>SÉZARY SYNDROME<sup>1,3,4</sup></h4>
                   
                        {pageElements &&
                    renderFormattedText(pageElements.lightBlueSectionTwo.value, "pBottom")}
                </div>
            </div>
            <Spacer className={'spacer--arrow-nineteen'}/>
        </div>
    )
}

export default SectionTwo;
