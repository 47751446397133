import React, { useEffect, useState } from "react";
import { connect, Provider } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import store from "./redux/redux-store";
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import ScrollToTop from "./components/ScrollTop";
import GeneralPublicPageContainer from "./pages/GeneralPublicPage/PatientPageContainer"
import Cookie from "./components/Cookie/Cookie";



const history = createBrowserHistory()
ReactGA.initialize('UA-192105055-1');
history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
    console.log(location.pathname)
});

const App = () => {

    return (
        <>
           

            <div className="bodyContainer">
                <ScrollToTop />
                <Switch>
                    <Route path='/' exact render={() => <GeneralPublicPageContainer />} />
                   
                </Switch>

            </div>


        </>
    )
};

const mapStateToProps = (state) => ({});

let AppContainer = connect(mapStateToProps, null)(App);

const SiteApp = () => {
    return <Router history={history}>
        <Provider store={store}>
            <AppContainer />
        </Provider>
    </Router>
};

export default SiteApp;
