import React from "react";
import {connect} from "react-redux";
import Footer from "./Footer";
import './Footer.scss';



    const FooterContainer = ({footerNav, footerNavPatient, setAdverseOpened}) => {
        return (
            <Footer footerNav={footerNav} footerNavPatient={footerNavPatient} setAdverseOpened={setAdverseOpened} />
            
        );
    }
const mapStateToProps = (state) => ({
    footerNav: state.nav.footerNav,
    footerNavPatient: state.nav.footerNavPatient
});


export default connect(mapStateToProps, null)(FooterContainer);