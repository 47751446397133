import React, { useState} from "react";

import PatientBanner from "./PatientBanner";
import SectionTwo from "./SectionTwo";
import SectionOne from "./SectionOne";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";
import FooterContainer from "../../components/FooterPatient/FooterContainer";
import AdverseModal from "../../components/AdverseReportingModal/AdverseModal";


const PatientPage = () => {
    const [isAdverseOpened, setAdverseOpened] = useState(false);
    return (
        <>
            <PatientBanner/>
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <SectionFour />
            <SectionFive />
            <FooterContainer setAdverseOpened={setAdverseOpened}/>
            <AdverseModal isAdverseOpened={isAdverseOpened} setAdverseOpened={setAdverseOpened}/>
        </>
    );
}

export default PatientPage;
