import React from "react";

import closeBtn from '../../assets/img/001/btn-close-adverse.png'



import './AdverseModal.scss';

const AdverseModal = ({isAdverseOpened, setAdverseOpened}) => {
    return (
        <div id="adverse" className={`adverse-modal ${isAdverseOpened && 'adverse-modal--show'}`}>
            <div className="align-height">
                <div className="adverse-modal__container">
                    <button onClick={() => setAdverseOpened(false)}>
                        <img src={closeBtn} alt="Close button" id="adClose"/>
                    </button>
                    <p><b>Reporting of side effects</b></p>
                   <p> If you get any side effects, talk to your doctor or nurse. This includes any
possible side effects not listed in the Patient Information Leaflet. You can also
report side effects directly via the Yellow Card Scheme. Website: <a href="https://yellowcard.mhra.gov.uk" target="_blank">www.mhra.gov.uk/yellowcard</a> or search MHRA Yellow Card in the Google Play or Apple App Store.                                                 
                            By reporting side effects, you can help provide more information on the safety of this medicine.</p>
                   
                    

                </div>
            </div>
        </div>
)
}

export default AdverseModal;