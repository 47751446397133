import React from "react";
import {connect} from "react-redux";

import PatientPage from "./PatientPage";

const PatientPageContainer = () => {
    return (
        <PatientPage />
    );
}

const mapStateToProps = (state) => ({

});


export default connect(mapStateToProps, null)(PatientPageContainer);