import React from "react";
import { useFetchElements } from "../../utils/hooks";
import { pageIds } from "../../api/content";


const SectionFive = () => {
    const pageElements = useFetchElements(pageIds.generalpublicpage);
    return (
        <div className="limit small">
            <p className="title txtOrange">References</p>
            <ol className="pBottom">
            
                    <li>Skin (cutaneous) T-cell lymphoma. Lymphoma Action website. <a onClick={ () => {if(window.confirm('You are about to leave the site. Are you sure you want to continue?')){window.location.href='https://lymphoma-action.org.uk/'};}} style={{color: 'blue'}}>https://lymphoma-action.org.uk/types-lymphoma-skin-lymphoma/skincutaneous-t-cell-lymphoma</a>. Updated April 2019. Accessed May 2022.</li>
                    <li>Skin lymphoma. Lymphoma Action website. <a onClick={ () => {if(window.confirm('You are about to leave the site. Are you sure you want to continue?')){window.location.href='https://lymphoma-action.org.uk/types-lymphoma/skin-lymphoma'};}} style={{color: 'blue'}}>https://lymphoma-action.org.uk/types-lymphoma/skinlymphoma</a>. Published March 2016. Updated February 2018. Accessed May 2022.</li>
                    <li>Tawa M, Kopp E, McCann S, Cantrell W. Cutaneous T-Cell Lymphoma: Optimizing Care in Patients Receiving Anti-CCR4 Monoclonal Antibody Mogamulizumab. Clin J Oncol Nurs. 2019;23(4):E73–E80.</li>
                    <li>Swerdlow SH, Campo E, Harris NL, Jaffe ES, Pileri SA, Stein H, Thiele J, eds. WHO Classification of Tumours of Haematopoietic and Lymphoid Tissues (Revised 4th edition). IARC: Lyon; 2017.</li>
                    <li>Cutaneous Lymphoma Foundation, Lymphoma Action and Lymphoma Coalition Europe. Cutaneous lymphoma – a patient’s guide. 2019. Available from: <a onClick={ () => {if(window.confirm('You are about to leave the site. Are you sure you want to continue?')){window.location.href='https://lymphoma-action.org.uk/sites/default/files/media/documents/2019-06/Cutaneous%20lymphoma%20-%20patient%26%23039%3Bs%20guide%20-%20English%20language%20source%20document%20-%20final%20version%20for%20publication%20-%20April%202019.pdf'};}} style={{color: 'blue'}}>https://lymphoma-action.org.uk/sites/default/files/media/documents/2019-06/Cutaneous%20lymphoma%20-20patient%26%23039%3Bs%20guide%20-%20English%20language%20source%20document%20-%20final%20version%20for%20publication%20-%20April%202019.pdf</a> Last Accessed: Accessed May 2022.</li>
                    <li>CL Foundation: Skin-Directed Therapies. Available from: <a onClick={ () => {if(window.confirm('You are about to leave the site. Are you sure you want to continue?')){window.location.href='https://www.clfoundation.org/skin-directed-therapies'};}} style={{color: 'blue'}}>https://www.clfoundation.org/skin-directedtherapies</a>. Last Accessed: May 2022.</li>

            </ol>
        </div>
    )
}

export default SectionFive;
