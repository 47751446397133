import React from "react";

import logo from '../../assets/img/001/logo-kyowa-kirin.png';

const LimitInfo = () => {
    return (
        <div className="limit limit--small-width text-center small">
            <img src={logo} alt="KYOWA KIRIN logo"
                 className="kyowa"/>
            <p>
            POTELIGEO<sup>®</sup> and POTELLIGENT<sup>®</sup> are registered trademarks of the Kyowa Kirin Group.<br></br>© 2022 Kyowa Kirin International plc. All rights reserved.<br></br>KKI/UK/POT/0065
            <br></br>Date of preparation: May 2022
            </p>
        </div>
    )
}


export default LimitInfo;