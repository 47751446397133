import React from "react";

import not_a_hcp_03 from '../../assets/img/001/not-a-hcp-03.png';
import { useFetchElements } from "../../utils/hooks";
import { pageIds } from "../../api/content";

import { renderFormattedText } from "../../utils/helpers";

const SectionFour = () => {
    const pageElements = useFetchElements(pageIds.generalpublicpage);
    return (
        <div className="lPurple">
            <div className="limit limit--purple">
                <h1>Further information and support</h1>
                {pageElements &&
                    renderFormattedText(pageElements.purpleSectionSectionOne.value, "pBottom")}
                <div className="column-item column-item--margin-top">
                    <img src={not_a_hcp_03} alt="" className="sqM" />
                    <p><b>Cutaneous Lymphoma Foundation:<br />
                        <a className="custom-link" onClick={ () => {if(window.confirm('You are about to leave the site. Are you sure you want to continue?')){window.location.href='https://www.clfoundation.org'};}}>clfoundation.org</a><br />
                        <br />
                            Lymphoma Coalition Europe:<br />
                            <a onClick={ () => {if(window.confirm('You are about to leave the site. Are you sure you want to continue?')){window.location.href='https://www.lymphomacoalition.org/europe'};}} className="dont-break-out custom-link">lymphomacoalition.org/europe</a></b></p>
                </div>
            </div>
        </div>
    )
}

export default SectionFour;
