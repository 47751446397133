const initialState = {

    siteNav: [
        {
            title: 'About MF/SS',
            url: '/about',
            subMenu: [
                {
                    title: 'Introduction',
                    url: '/about'
                },
                {
                    title: 'CTCL',
                    url: '/about#sec1'
                },
                {
                    title: 'Mycosis Fungoides',
                    url: '/about#sec2'
                },
                {
                    title: 'Sézary-Syndrome',
                    url: '/about#sec3'
                },
                {
                    title: 'Quality of Life',
                    url: '/about#sec4'
                },
                {
                    title: 'CCR4 as a treatment target',
                    url: '/about#sec5'
                }
            ]
        },
        {
            title: 'POTELIGEO®',
            url: '/poteligeo',
            subMenu: [
                {
                    title: 'Introduction',
                    url: '/poteligeo'
                },
                {
                    title: 'Mode of Action',
                    url: '/mode-of-action'
                },
                {
                    title: 'Mavoric Trial Design',
                    url: '/mavoric-trial-design'
                },
                {
                    title: 'Efficacy',
                    url: '/efficacy'
                },
                {
                    title: 'Safety',
                    url: '/safety'
                },
                {
                    title: 'Dosing and Administration',
                    url: '/dosing-and-administration'
                }
            ]
        },
        {
            title: 'Resources, Support and News',
            url: '/resources-and-support',
        }
    ],
    mobileNav: [
        {
            title: 'ABOUT MF/SS',
            url: '/about',
            subMenu: [
                {
                    title: 'Introduction',
                    url: '/about'
                },
                {
                    title: 'CTCL',
                    url: '/about#sec1'
                },
                {
                    title: 'Mycosis fungoides',
                    url: '/about#sec2'
                },
                {
                    title: 'Sézary-Syndrome',
                    url: '/about#sec3'
                },
                {
                    title: 'Quality of life',
                    url: '/about#sec4'
                },
                {
                    title: 'CCR4 as a treatment target',
                    url: '/about#sec5'
                }
            ]
        },
        {
            title: 'POTELIGEO®',
            url: '/poteligeo',
            subMenu: [
                {
                    title: 'Introduction',
                    url: '/poteligeo'
                },
                {
                    title: 'Mode of action',
                    url: '/mode-of-action'
                },
                {
                    title: 'MAVORIC trial design',
                    url: '/mavoric-trial-design',
                    subMenu: [
                        {
                            title: 'Introduction',
                            url: '/mavoric-trial-design'
                        },
                        {
                            title: 'Trial design',
                            url: '/mavoric-trial-design#sec1'
                        },
                        {
                            title: 'Eligibility criteria',
                            url: '/mavoric-trial-design#sec2'
                        },
                        {
                            title: 'Study endpoints',
                            url: '/mavoric-trial-design#sec3'
                        },
                        {
                            title: 'Patient disposition',
                            url: '/mavoric-trial-design#sec4'
                        },
                        {
                            title: 'Assessment of progression',
                            url: '/mavoric-trial-design#sec5'
                        },
                        {
                            title: 'PFS as a primary endpoint',
                            url: '/mavoric-trial-design#sec6'
                        },
                    ]
                },
                {
                    title: 'Efficacy',
                    url: '/efficacy',
                    subMenu: [
                        {
                            title: 'Introdcution',
                            url: '/efficacy'
                        },
                        {
                            title: 'Progression-free survival',
                            url: 'efficacy#sec1'
                        },
                        {
                            title: 'Overall response',
                            url: 'efficacy#sec2'
                        },
                        {
                            title: 'Response by disease compartment',
                            url: 'efficacy#sec3'
                        },
                        {
                            title: 'Response by CTCL subtype',
                            url: 'efficacy#sec4'
                        },
                        {
                            title: 'Quality of life outcomes',
                            url: 'efficacy#sec5'
                        },
                        {
                            title: 'MAVORIC investigators summary',
                            url: 'efficacy#sec6'
                        }
                    ]
                },
                {
                    title: 'Safety',
                    url: '/safety'
                },
                {
                    title: 'Dosing and administration',
                    url: '/dosing-and-administration',
                    subMenu: [
                        {
                            title: 'Introduction',
                            url: '/dosing-and-administration'
                        },
                        {
                            title: 'Dosing schedule',
                            url: '/dosing-and-administration#sec1'
                        },
                        {
                            title: 'Dosing schedule adjustments',
                            url: '/dosing-and-administration#sec2'
                        },
                        {
                            title: 'Dose modifications for adverse reasons',
                            url: '/dosing-and-administration#sec3'
                        }
                    ]
                },
            ]
        },
        {
            title: 'Resources, Support and News',
            url: '/resources-and-support',
        },
        {
            title: 'PRESCRIBING INFORMATION',
            url: '/prescribing-information'
        }
    ],
    footerNav: [
        {
            title: 'Terms of Use',
            url: '/Terms'
        },
        {
            title: 'Contact Us',
            url: '#contact'
        },
        {
            title: 'About Kyowa Kirin',
            url: '/kkihome'
        },
        {
            title: 'Cookies and privacy policy',
            url: '/cookies'
        }
    ],
    footerNavApproved: [
        {
            title: 'Prescribing Information',
            url: '/prescribing-information'
        },
        {
            title: 'References',
            url: '/references'
        },
        {
            title: 'Adverse Events',
            url: '#open-modal'
        },
    ],

    footerNavPatient: [
        {
            title: 'Patient Information Leaflet',
            url: '/prescribing-information-patient'
        },

        {
            title: 'Reporting of side effects',
            url: '#open-modal'
        },

        {
            title: 'SPC',
            url: '/SPC'
        },

        {
            title: 'EPAR',
            url: '/EPAR'
        },
    ]
};


const appReducer = (state = initialState, action) => {
    switch (action.type) {
        /* there will be actions  */

        default:
            return state;
    }
};

export default appReducer;