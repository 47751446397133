// Hub configuration
export const DOMAIN_NAME = "https://content-us-1.content-cms.com";
export const CONTENT_HUB_ID = "bd898928-9436-4bd8-9ae9-77ab22a0b19c";

// API endpoints
const DELIVERY_URL = "delivery/v1";
const RENDER_CONTEXT_URL = "rendering/context/";
export const BASE_URL = `${DOMAIN_NAME}/api/${CONTENT_HUB_ID}`;
export const BASE_DELIVERY_URL = `${BASE_URL}/${DELIVERY_URL}`;
export const BASE_RENDER_URL = `${BASE_DELIVERY_URL}/content`;
export const BASE_RENDER_CONTEXT_URL = `${BASE_URL}/${DELIVERY_URL}/${RENDER_CONTEXT_URL}`;
