import React from "react";

import './PatientBanner.scss';

const PatientBanner = () => {

    return (
        <div className="patient-banner">
            <div className="hNotHCP">
                <br></br>
                <h1>What is cutaneous <span>T-cell</span> lymphoma?</h1>
              
            </div>
        </div>
    );
}

export default PatientBanner;
