import React from "react";

import './Spacer.scss';

const Spacer = ({className}) => {
    return (
        <div className={`spacer ${className}`}/>
    )
}

export default Spacer;