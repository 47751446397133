import React from "react";
import LimitInfo from "./LimitInfo";
import ApproverNav from "./ApprovedNav";

const Footer = ({footerNav, footerNavPatient, setAdverseOpened}) => {

    const adverseOpen = () => {
        setAdverseOpened(true);
        console.log(1);
    }

    
    return (
        <div id="onLoadFoot">
            <LimitInfo/>
            <div className="footer">
                {footerNav ? footerNav.map((item, i) =>
                    <span key={i}>
                        {i !== 0 ? <div className="nbspF"> &nbsp;|&nbsp; </div> : null}
                        {item.url === '#open-modal' ? (
                            <button onClick={adverseOpen} className="footer__link" key={item.i} data-title={item.title}>{item.title}</button>
                        ) : item.url === '/Terms' ? (
                            <button className="footer__link" key={item.i} data-title={item.title} onClick={ () => {if(window.confirm('You are about to leave the site. Are you sure you want to continue?')){window.location.href='https://www.kyowakirin.com/terms_and_conditions/index.html'};}}>{item.title}</button>
                        ) : item.url === '/kkihome' ? (
                            <button className="footer__link" key={item.i} data-title={item.title} onClick={ () => {if(window.confirm('You are about to leave the site. Are you sure you want to continue?')){window.location.href='https://www.kyowakirin.com/index.html'};}}>{item.title}</button>
                        ) : item.url === '/cookies' ? (
                            <button className="footer__link" key={item.i} data-title={item.title} onClick={ () => {if(window.confirm('You are about to leave the site. Are you sure you want to continue?')){window.location.href='https://www.kyowakirin.com/privacy_policy/index.html'};}}>{item.title}</button>
                        ) : (
                            <button className="footer__link" key={item.i} data-title={item.title} onClick={ () => {if(window.confirm('You are about to leave the site. Are you sure you want to continue?')){window.location.href='https://www.kyowakirin.com/contact_us/index.html'};}}>{item.title}</button>
                        ) }
                    </span>
                ) : null}
                {footerNavPatient ? <ApproverNav footerNavPatient={footerNavPatient} adverseOpen={adverseOpen}/> : null}
            </div>

            
        </div>
    )
}

export default Footer;