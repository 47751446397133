export const pageIds = {
  home: "eb38d449-78dd-459b-9e4d-91f43ee4868e",
  references: "6c65283f-22e7-4050-a87b-5749bc6a2e5f",
  uber: "f445a534-7b85-4acb-b2e0-a37bb5a8ca06",
  wirkmechanismus: "4791a5a4-f8d0-4178-a1fc-ada3d9e52ffb",
  mavoric: "4e9ac732-71e8-4a38-8a54-cd54a10f34c4",
  dosing: "d29c90d6-b168-4649-b266-bc5bfc855eed",
  informationsmaterial: "5d0733d7-8970-4cfb-b950-ada3c8c03a4d",
  wirksamkeit: "61729adb-c4b9-4998-ade4-e2969d7cd0af",
  safety: "a1886ca9-12aa-4218-b6c8-691f574c2938",
  homesquares: "d5512d97-9a4e-4711-8a2a-90bebfede891",
  patientpage:"8a875797-8553-4bf9-b03c-f535def49536",
  generalpublicpage:"e37bfde5-bad1-432e-9d30-b2550a8ce018",
  pi: "5be8ee12-188c-4d13-9e35-ad661762b434"


  
};

/* ther rest of theses are examples and aren't in use */
export const contentIds = {
 // styleGuide: "8fe0a373-d555-4a02-b0c1-3c5ea7423a7f",
//  banner: "9ad99f88-07e4-4247-89a6-ba5bf908e0ac",
////  companyStatement: "7fe7d48e-6665-408b-84ee-6515628073f0",
//  companyInformation: "516658d0-cc4d-4b74-a0c3-9082b55cbb62"
};

export const types = {
  region: "Region",
  country: "Country",
  galleryImage: "Gallery Image",
  article: "COVID Update",
  faqItem: "FAQ Item",
  resource: "Resource"
};

export const searchKeys = {
  updateTitle: "string1",
  articleDate: "sortableDate1",
  categories: "string2",
  galleryImageTitle: "string3",
  faqQuestion: "string4",
  topUpdate: "boolean1",
  displayOrder: "Most Recent"
};
