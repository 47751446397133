import {
  BASE_RENDER_URL,
  BASE_DELIVERY_URL,
  BASE_RENDER_CONTEXT_URL
} from "./endpoints";
import { types, searchKeys } from "./content";

const checkStatus = response => {
  if (response.ok) {
    return response;
  }

  throw Error(response.statusText);
};

const parseJSON = response => response.json();

export const handleError = error => {
  console.error("Request failed: ", error);
};

const fetcher = {
  get: (path, params) =>
    fetch(path, params)
      .then(checkStatus)
      .then(parseJSON)
      .catch(e => {
        console.error(
          "path: " + path + " params: " + params + " errorMessage: " + e
        );
      })
};

const getContentById = id => fetcher.get(`${BASE_RENDER_URL}/${id}`);

const getRenderContentById = id =>
  fetcher.get(`${BASE_RENDER_CONTEXT_URL}/${id}`);

const search = query => fetcher.get(`${BASE_DELIVERY_URL}/search?${query}`);

const searchDocument = query => search(`&fl=document:[json]${query}`);

const searchFacet = text =>
  fetcher.get(
    `${BASE_DELIVERY_URL}/search?
q=text:${text}*
&fq=type:((${types.article})%20OR%20(${types.faqItem}))
&rows=0
&facet=true
&facet.field=type
&facet.field=${searchKeys.updateTitle}
&facet.field=${searchKeys.faqQuestion}
&facet.mincount=1
&fl=${searchKeys.articleTitle},${searchKeys.faqQuestion},type
&facet.limit=5`
  );

export default {
  getContentById,
  getRenderContentById,
  searchDocument,
  search,
  searchFacet
};
