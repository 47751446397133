import { useState, useEffect } from "react";

import API, { handleError } from "../api/api";
import { getQueryFromParams } from "./helpers";

export const useFetchContent = contentId => {
  const [state, setState] = useState(null);

  useEffect(() => {
    API.getContentById(contentId)
      .then(content => {
        setState(content);
      })
      .catch(handleError);
  }, [contentId]);

  return state;
};

export const useFetchContext = contentId => {
  const [state, setState] = useState(null);

  useEffect(() => {
    API.getRenderContentById(contentId)
      .then(content => {
        setState(content);
      })
      .catch(handleError);
  }, [contentId]);

  return state;
};

export const useFetchElements = contentId => {
  const content = useFetchContent(contentId);
  return content && content.elements;
};

export const useFetchContextElements = contentId => {
  const content = useFetchContext(contentId);
  return content && content.elements;
};

export const useSearch = (params, select = r => r) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    API.search(getQueryFromParams(params)).then(data =>
      setResult(select(data))
    );
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return result;
};
