import React from "react";

import not_a_hcp_01 from '../../assets/img/001/not-a-hcp-01.png';
import not_a_hcp_02 from '../../assets/img/001/not-a-hcp-02.png';
import { useFetchElements } from "../../utils/hooks";
import { pageIds } from "../../api/content";
import { renderFormattedText } from "../../utils/helpers";
const SectionThree = () => {
    const pageElements = useFetchElements(pageIds.generalpublicpage);
    return (
        <div className="lGreen">
            <div className="limit">
                <h1>Managing MF or SS<sup>5,6</sup></h1>
                {pageElements &&
                    renderFormattedText(pageElements.greenSection.value, "pBottom")}

            </div>
        </div>
    )
}

export default SectionThree;
